import React, { FC, Fragment, useState, useRef } from 'react';
import { Popover, MobileDrawer, ActionsMenuLayout } from 'wix-ui-tpa';
import MoreIcon from 'wix-ui-icons-common/on-stage/More';

import { st, classes } from './ActionsMenu.st.css';

export interface ActionsMenuItemProps {
  label: string;
  icon?: JSX.Element;
  handleOnClick: () => void;
  biData?: { evid: number; [key: string]: any };
}

interface ActionsMenuProps {
  isMobile?: boolean;
  actions: ActionsMenuItemProps[];
  buttonClassName?: string;
  onAfterMenuOpen?: (callback: () => void) => void;
  onAfterMenuClose?: (callback: () => void) => void;
  onAfterMenuItemClick?: (
    callback: () => void,
    bidata?: { evid: number; [key: string]: any },
  ) => void;
}

// @TODO fix Popover and ActionsMenu issues, when https://wix.slack.com/archives/CJRU3U97A/p1644407447944359 is done
const ActionsMenu: FC<ActionsMenuProps> = ({
  isMobile,
  actions = [],
  buttonClassName,
  onAfterMenuOpen,
  onAfterMenuClose,
  onAfterMenuItemClick,
}) => {
  const popoverRef = useRef<any>();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleMenu = () => setIsOpen(!isOpen);
  const handleCloseMenu = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      requestAnimationFrame(() => popoverRef.current.focus());
    }
  }, [isOpen]);

  if (actions.length === 0) {
    return null;
  }

  const handleOnClick = () => {
    if (isOpen) {
      if (onAfterMenuClose) {
        onAfterMenuClose(handleToggleMenu);
      } else {
        handleToggleMenu();
      }
    } else {
      if (onAfterMenuOpen) {
        onAfterMenuOpen(handleToggleMenu);
      } else {
        handleToggleMenu();
      }
    }
  };

  const actionButton = (
    <button
      aria-label="menu"
      className={st(classes.buttonContainer, buttonClassName)}
      onClick={e => {
        e.stopPropagation();
        handleOnClick();
      }}
    >
      <MoreIcon />
    </button>
  );

  const menuContent = (
    <div className={st(classes.menuLayoutContainer)}>
      <ActionsMenuLayout
        // @TODO using focusedIndex is broken and causes scroll to top in wix-ui-tpa v3
        // focusedIndex={0}
        className={st(classes.menuLayout)}
      >
        {actions.map((action, index) => {
          let clickHandler = action.handleOnClick;

          if (onAfterMenuItemClick) {
            clickHandler = () => {
              handleToggleMenu();
              onAfterMenuItemClick(action.handleOnClick, action.biData);
            };
          }

          return (
            <ActionsMenuLayout.Item
              aria-label={action.label}
              key={index}
              content={action.label}
              prefixIcon={action.icon}
              onClick={clickHandler}
            />
          );
        })}
      </ActionsMenuLayout>
    </div>
  );

  return isMobile ? (
    <Fragment>
      {actionButton}
      <MobileDrawer isOpen={isOpen} onRequestClose={handleToggleMenu}>
        {menuContent}
      </MobileDrawer>
    </Fragment>
  ) : (
    <Popover
      tabIndex={-1}
      // @ts-expect-error
      ref={popoverRef}
      shown={isOpen}
      showArrow={false}
      placement="bottom"
      onClickOutside={handleCloseMenu}
      onTabOut={handleCloseMenu}
      onEscPress={handleCloseMenu}
      contentClassName={st(classes.popoverContent)}
    >
      <Popover.Element>{actionButton}</Popover.Element>
      <Popover.Content>{menuContent}</Popover.Content>
    </Popover>
  );
};

export default ActionsMenu;

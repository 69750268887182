import React, { FC } from 'react';
import { isNumber } from 'lodash';
import LikeHeartIcon from 'wix-ui-icons-common/on-stage/LikeHeart';
import CommentIcon from 'wix-ui-icons-common/on-stage/Comment';
import ReplyIcon from 'wix-ui-icons-common/on-stage/Reply';
import VisibleIcon from 'wix-ui-icons-common/on-stage/Visible';

import { formatCount } from './StatsBar.utils';
import { classes, st } from './StatsBar.st.css';

interface StatComponentProps {
  isRTL: boolean;
  value: number | string;
  iconComponent: FC<any>;
}

const StatComponent: FC<StatComponentProps> = ({
  isRTL,
  value,
  iconComponent: Icon,
}) => {
  const styleStateProps = { rtl: isRTL };

  return (
    <div className={st(classes.statContainer, styleStateProps)}>
      <div className={st(classes.iconContainer, styleStateProps)}>
        <Icon size={20} />
      </div>
      <div>{value}</div>
    </div>
  );
};
interface StatsBarProps {
  isRTL?: boolean;
  score?: number;
  likes?: number;
  comments?: number;
  replies?: number;
  views?: number;
}

const StatsBar: FC<StatsBarProps> = ({
  isRTL = false,
  score,
  likes,
  comments,
  views,
  replies,
}) => {
  return (
    <div className={st(classes.container)}>
      {isNumber(score) && (
        <div className={st(classes.statContainer, { rtl: isRTL })}>
          <div className={st(classes.iconContainer, { rtl: isRTL })}>
            {/* @TODO temporary inline svg, while icon is not available in wix-ui-tp */}
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.4058 10.6244L15.4059 10.6245C15.5342 10.8105 15.5244 11.0078 15.4183 11.1755C15.3081 11.3498 15.0826 11.5 14.7676 11.5H1.23236C0.917412 11.5 0.691912 11.3498 0.581657 11.1755C0.475563 11.0078 0.465847 10.8105 0.594114 10.6245L0.594138 10.6244L7.3624 0.8058L7.36268 0.805396C7.49325 0.615582 7.73021 0.500002 8.00015 0.500002C8.27009 0.500002 8.50767 0.615606 8.6389 0.80589L15.4058 10.6244Z"
                stroke="currentColor"
              />
            </svg>
          </div>
          <div>{formatCount(score)}</div>
          <div className={st(classes.iconContainer, { rtl: isRTL })}>
            {/* @TODO temporary inline svg, while icon is not available in wix-ui-tp */}
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.594162 1.37561L0.594113 1.37554C0.465846 1.1895 0.475563 0.992217 0.581656 0.824474C0.691912 0.65015 0.917411 0.5 1.23236 0.5L14.7676 0.5C15.0826 0.5 15.3081 0.65015 15.4183 0.824474C15.5244 0.992217 15.5342 1.1895 15.4059 1.37554L15.4059 1.37557L8.6376 11.1942L8.63732 11.1946C8.50675 11.3844 8.26979 11.5 7.99985 11.5C7.72991 11.5 7.49233 11.3844 7.3611 11.1941L0.594162 1.37561Z"
                stroke="currentColor"
              />
            </svg>
          </div>
        </div>
      )}
      {isNumber(likes) && (
        <StatComponent
          isRTL={isRTL}
          value={formatCount(likes)}
          iconComponent={LikeHeartIcon}
        />
      )}
      {isNumber(comments) && (
        <StatComponent
          isRTL={isRTL}
          value={formatCount(comments)}
          iconComponent={CommentIcon}
        />
      )}
      {isNumber(replies) && (
        <StatComponent
          isRTL={isRTL}
          value={formatCount(replies)}
          iconComponent={ReplyIcon}
        />
      )}
      {isNumber(views) && (
        <StatComponent
          isRTL={isRTL}
          value={formatCount(views)}
          iconComponent={VisibleIcon}
        />
      )}
    </div>
  );
};

export default StatsBar;

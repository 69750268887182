import React, { FC } from 'react';

import { StatsBar } from '@common/components';
import { classes, st } from './ItemFooter.st.css';

interface ItemFooterProps {
  isRTL?: boolean;
  stats: {
    likes?: number;
    comments?: number;
    views?: number;
  };
}

const ItemFooter: FC<ItemFooterProps> = ({ isRTL, stats }) => {
  return (
    <div className={st(classes.statsBar)}>
      <StatsBar
        isRTL={isRTL}
        likes={stats.likes}
        comments={stats.comments}
        views={stats.views}
      />
    </div>
  );
};

export default ItemFooter;

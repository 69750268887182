import React, { FC } from 'react';
import { Image } from 'wix-ui-tpa';

import { classes, st } from './Badge.st.css';

interface BadgeProps {
  className: string;
  icon?: string;
  title: string;
}

const Badge: FC<BadgeProps> = ({ className, icon, title }) => {
  let iconImage;

  if (icon) {
    iconImage = <Image alt={title} src={icon} width={20} height={20} />;
  }

  return (
    <div className={className}>
      {iconImage && <div>{iconImage}</div>}
      <div className={st(classes.badgeTitle)}>{title}</div>
    </div>
  );
};

export default Badge;

import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Owner } from '@api/member.models';
import { ActionsMenu, ActionsMenuItemProps, Badge } from '@common/components';
import { classes, st } from './ItemHeader.st.css';

interface ItemHeaderProps {
  isMobile?: boolean;
  owner: Owner;
  createdDate: Date;
  availableActions: ActionsMenuItemProps[];
  logEvent: (eventData: { [key: string]: any }) => void;
}

const ItemHeader: FC<ItemHeaderProps> = ({
  isMobile,
  owner,
  createdDate,
  availableActions,
  logEvent,
}) => {
  const { t } = useTranslation();

  const badgeToRender =
    owner.badges.length > 0 ? (
      <Badge
        className={st(classes.badgeContainer)}
        icon={owner.badges[0].icon}
        title={owner.badges[0].title!}
      />
    ) : null;

  return (
    <div className={st(classes.headerContainer)}>
      <div className={st(classes.headerContent)}>
        <div className={st(classes.itemHeaderOwner)}>
          <div className={st(classes.itemHeaderOwnerName)}>{owner.name}</div>
          {badgeToRender}
        </div>
        <div className={st(classes.headerCreatedDate)}>
          {t('fullDate', { time: new Date(createdDate) })}
        </div>
      </div>

      <div>
        <ActionsMenu
          buttonClassName={classes.actionButton}
          isMobile={isMobile}
          actions={availableActions}
          onAfterMenuOpen={clickHandler => {
            clickHandler();

            logEvent({
              evid: 524,
            });
          }}
          onAfterMenuItemClick={(clickHandler, itemBiData) => {
            clickHandler();

            if (itemBiData) {
              logEvent(itemBiData);
            }
          }}
        />
      </div>
    </div>
  );
};

export default ItemHeader;

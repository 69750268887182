import React, { FC } from 'react';
import { useTranslation, TFunction } from '@wix/yoshi-flow-editor';
import DeleteIcon from 'wix-ui-icons-common/on-stage/Delete';
import LinkIcon from 'wix-ui-icons-common/on-stage/Link';

import { DecoratedPost } from '@api/member.models';
import { useBiLogger } from '@common/providers';
import { ItemContainer, ItemHeader, ItemContent, ItemFooter } from './elements';

const getAvailableActions = (
  data: DecoratedPost,
  { handleDeleteItem, handleCopyItemLink }: any,
  t: TFunction,
) => {
  const availableActions = [];

  if (handleCopyItemLink) {
    availableActions.push({
      biData: {
        action: 'copy-link',
        evid: 526,
      },
      label: t('share-link.copy'),
      icon: <LinkIcon />,
      handleOnClick: () => handleCopyItemLink(data.url),
    });
  }

  if (handleDeleteItem) {
    availableActions.push({
      biData: {
        action: 'delete-post',
        evid: 526,
      },
      label: t('post-actions.delete-post'),
      icon: <DeleteIcon />,
      handleOnClick: () => handleDeleteItem(data),
    });
  }

  return availableActions;
};

interface PostListItemProps {
  isRTL?: boolean;
  isMobile?: boolean;
  data: DecoratedPost;
  handleOpenItem?: (postUrl: string) => void;
  handleDeleteItem?: (post: DecoratedPost) => void;
  handleCopyItemLink?: (postUrl: string) => void;
}

const PostListItem: FC<PostListItemProps> = ({
  isRTL,
  isMobile,
  data,
  handleOpenItem,
  handleDeleteItem,
  handleCopyItemLink,
}) => {
  const { t } = useTranslation();
  const { logEvent } = useBiLogger();

  const availableActions = getAvailableActions(
    data,
    {
      handleDeleteItem,
      handleCopyItemLink,
    },
    t,
  );

  const COMMON_BI_DATA = {
    type: 'post',
    post_id: data.id,
  };

  return (
    <ItemContainer isMobile={isMobile}>
      <ItemHeader
        isMobile={isMobile}
        owner={data.owner}
        createdDate={data.createdDate}
        availableActions={availableActions}
        logEvent={(biData: any) => {
          logEvent({
            ...COMMON_BI_DATA,
            ...biData,
          });
        }}
      />
      <ItemContent
        isMobile={isMobile}
        data={data}
        handleOpenItem={() => {
          if (handleOpenItem) {
            logEvent({
              ...COMMON_BI_DATA,
              evid: 516,
            });
            handleOpenItem(data.url);
          }
        }}
      />
      <ItemFooter isRTL={isRTL} stats={data.stats} />
    </ItemContainer>
  );
};

export default PostListItem;
